/* View existed Address Start */
.get-address-view {
  width: 100%;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 20px;
  padding: 13px;
}

.dashboard-carousel-section {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.get-address-view:active {
  border: solid 2px #15aa71;
  border-radius: 10px;
}

.get-address-view:hover {
  /* background: gainsboro; */
}

.selected-shipping-address {
  font-size: 14px;
  color: var(--color-black);
  font-weight: normal !important;
}

.payments-div-disable {
  opacity: 0.6;
  pointer-events: none;
}

.modal-show-addressList {
  height: 70vh;
  overflow-y: scroll;
}

.modal-show-addressList::-webkit-scrollbar {
  width: 4px;
}

.modal-show-addressList::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  width: 2px;
}

.modal-show-addressList::-webkit-scrollbar-thumb {
  background: #888;
}

.modal-show-addressList::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* View existed Address end */
.radius-10 {
  border-radius: 10px;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.hcb-beckfrom-ct-page .hcp-back-arrow {
  display: inline-block !important;
}

.cart-pg-mb-30 {
  margin-bottom: 24px;
}

.yt-currency-wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cart-pg-breadcrumbs {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
  margin-bottom: 30px;
}

.checkout-customized-itemName {
  color: var(--color-ButtonTextRegular);
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  line-height: 24px;
}

.cartno-wrap {
  margin-top: 52px;
  margin-bottom: 40px;
}

.cart-pg-current {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}

.empty-cartn-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 17px;
}

.empty-cart-text {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}

.empty-cart-btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--button-primary);
  border-color: var(--button-primary);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--button-text-primary);
}

.cart-page-main-title {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 30px;
}

.cart-prodict-info {
  width: 100%;
}

.cart-product-image {
  border-radius: 9px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
}

.cart-product-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.17;
  color: var(--color-black);
  margin-bottom: 0px;
  max-width: 310px;
  display: inline-block;
}

.cart-action-wrap {
  float: right;
}

.yt-product-code {
  color: silver;
  margin-bottom: 5px;
}

.yt-product-code {
  color: silver;
  margin-bottom: 5px;
  font-size: 11px;
}

.cart-action-separator {
  color: #b2b3b3;
  font-size: 17px;
  vertical-align: top;
}

.cart-prodict-type-container {
  max-width: 32vw;
  overflow-x: auto;
  margin-bottom: 20px;
}

.cart-product-head-container {
  display: flex;
}

.ml-40 {
  margin-left: 40px;
}

.mr-40 {
  margin-right: 40px;
}

@media (max-width: 768px) {
  .dashboard-carousel-section {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .cart-prodict-type-container {
    max-width: 54vw;
    margin-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .cart-prodict-type-container {
    display: inline;
  }

  .yt-product-code {
    font-size: 11px;
  }
}

/* width */
.cart-prodict-type-container::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.cart-prodict-type-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.cart-prodict-type-container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.cart-prodict-type-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cart-prodict-type th {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: #15aa71;
}

.cart-prodict-type th,
.cart-prodict-type td {
  padding: 3px;
}

.cart-product-price {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: #5b5b5b;
}

.cart-product-delete {
  font-size: 14px;
  color: var(--color-orange);
  cursor: pointer;
  display: inline;
}

.cart-product-wishlist {
  font-size: 14px;
  color: #30458c;
  cursor: pointer;
  display: inline;
}

.cart-prodict-amount th {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.15;
  text-align: left;
  color: var(--color-black);
}

.cart-product-amount-ttl {
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #000000;
  font-weight: normal;
}

.cart-product-amount-qty {
  text-align: center;
  display: block;
}

.cart-product-amount-price {
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #000000;
  display: block;
  margin-left: 4px;
}

.cart-prodict-amount thead th {
  padding-top: 0;
  /* padding-bottom: 3px; */
  padding-left: 0;
  padding-right: 0;
}

.cart-prodict-amount thead th:first-child {
  width: 60%;
}

.cart-prodict-amount tbody td,
.cart-prodict-total-amount tbody td,
.ccart-prodict-sub-total-amount tbody td,
.cart-prodict-sub-total-amount tbody td {
  padding: 0px 0;
}

.cart-divider {
  border-bottom: solid 1px #e2e2e2 !important;
  display: block;
  margin: 15px 0 20px;
  width: 100% !important;
}

.cart-coupon .form-group {
  position: relative;
}

.cart-coupon .form-control {
  border-radius: 6px;
  border: solid 1px #dbdbdb;
  height: auto;
  box-shadow: none;
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  padding: 21px;
}

.cart-coupon-btn {
  position: absolute;
  top: 50%;
  right: 12px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: var(--color-orange);
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 1.15;
  padding: 8px 15px;
}

.cart-proceed-to-checkput {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  opacity: 0.99;
  border-radius: 5px;
  /* background-color: var(--button-primary) !important; */
  background-color: var(--color-ButtonBackRegular);
  border-color: var(--button-primary) !important;
  color: var(--color-ButtonTextRegular);
  /* color: var(--button-text-primary) !important; */
}

.cart-proceed-to-checkput:hover {
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}

.cart-checkout-tile {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  text-align: left;
  color: var(--color-black);
}

.cart-select-aar-btn {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: black;
}

.checkout-form-wrap .form-group .form-control {
  box-shadow: none;
  border-bottom: 1px solid #dbdbdb !important;
  border-radius: 0;
}

.checkout-form-label {
  font-size: 18px;
  line-height: 1.14;
  text-align: left;
  margin: 0 0 0px 15px;
  color: var(--color-dark-grey);
}

.checkout-checkbox .form-group,
.yt-chekout-radio .form-group {
  padding-left: 25px;
  position: relative;
  line-height: 25px;
}

.yt-empty-cart-wrap {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}

.yt-empty-cart {
  height: 557px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-empty-basket {
  background: #f5f5f5;
  height: 188px;
  width: 188px;
  padding: 47px;
  border-radius: 100%;
  margin: auto;
}

.empty-cart-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.cart-pg-inner-wrap .checkout-checkbox .form-check-input {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
  margin: 0;
}

.cart-pg-inner-wrap .yt-checkout-form-chk-box,
.cart-pg-inner-wrap .yt-checkout-form-rado-box {
  position: absolute;
  cursor: pointer;
  margin: 0;
  left: 0;
  top: 0;
}

.cart-pg-inner-wrap .checkout-checkbox .yt-checkout-form-chk-box:before,
.cart-pg-inner-wrap .yt-checkout-form-rado-box:before {
  content: "";
  -webkit-appearance: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border: solid 1px #979797;
  background-color: #ffffff;
  border-radius: 3px;
  box-sizing: border-box;
}

.cart-pg-inner-wrap .yt-checkout-form-rado-box:before {
  border-radius: 100%;
  width: 15px;
  height: 15px;
  padding: 5px;
}

.yt-checkout-chekbox-label {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: var(--color-black);
}

.cart-pg-inner-wrap .checkout-checkbox .form-check-input:checked+label:before {
  background-color: #3f7a4f;
}

.cart-pg-inner-wrap .checkout-checkbox .form-check-input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 5;
}

.form-check-input:checked {
  background-color: #3f7a4f;
  border-color: #3f7a4f;
}

.cart-pg-inner-wrap .yt-chekout-radio .form-check-input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  width: 7px;
  height: 7px;
  z-index: 5;
  background: #3f7a4f;
  border-radius: 25px;
  left: 4px;
}

.cart-pg-inner-wrap .yt-chekout-radio .form-check-input:checked+label:before {
  border-color: #3f7a4f;
}

.cart-pg-inner-wrap .yt-chekout-radio .form-check-input {
  position: absolute;
  left: 20px;
  top: 0;
  z-index: 5;
  opacity: 0;
  right: 0;
  width: 100%;
  margin-top: 0;
  bottom: 0;
  height: 100%;
}

.checkout-form-wrap .form-check-label {
  line-height: 1.5;
}

.cart-coupon .error .cart-coupon-code-message {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-orange);
}

.cart-coupon .success .cart-coupon-code-message {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-green);
}

.cart-coupon-change-btn {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-orange);
  text-decoration: underline;
}

.cart-coupon .cart-coupon-bottom-wrapper {
  position: absolute;
  bottom: -42px;
  left: 0;
  right: 0;
  opacity: 0;
}

.cart-coupon .error .cart-coupon-bottom-wrapper,
.cart-coupon .success .cart-coupon-bottom-wrapper {
  opacity: 1;
}

form.yt-cart-disct-wrap .yt-form-cpn-err.form-group,
form.yt-cart-disct-wrap .success.form-group {
  margin-bottom: 20px !important;
}

.cart-coupon .success .cart-coupon-bottom-wrapper .error-message,
.cart-coupon .error .cart-coupon-bottom-wrapper .success-message,
.login-on-cart-page .lp-bottom-links {
  display: none;
}

.yt-login-wrap {
  margin-top: 0 !important;
}

.cart-prodict-amount thead th:last-child {
  text-align: right;
}

.cart-prodict-type tr td {
  font-size: 17px;
  line-height: 1.24;
  color: var(--color-black);
}

.cart-pg-list-image {
  width: 20%;
}

.cart-pg-list-prdt-info {
  width: 75%;
}

.cart-list-other-act {
  position: relative;
  min-width: 210px;
}

.cart-quantity-box {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 115px;
}

.checkout-form-wrap .yt-remove-arrow::-webkit-outer-spin-button,
.checkout-form-wrap .yt-remove-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

.checkout-form-wrap .yt-remove-arrow[type="number"] {
  -moz-appearance: textfield;
}

.cart-produt-list-wrap {
  padding: 40px 40px 28px;
}

.yt-cart-price-lister {
  padding: 10px 40px 24px 40px;
  margin-bottom: 24px;
}

.checkout-form-wrap .cart-pg-inner-wrap {
  padding: 40px 30px 30px;
}

.yt-cm-lt-col {
  padding-right: 7px;
}

.yt-cm-rt-col {
  padding-left: 7px;
}

@media (min-width: 768px) {
  .cart-proceed-to-checkput.cart-btn-bill-pg {
    display: none;
  }

  .img-empty-basket {
    height: 150px;
    width: 150px;
  }

  .cart-pg-list-image .cart-product-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  .cart-pg-list-image .cart-product-image {
    width: 100%;
    height: 10vw;
  }
}

@media (max-width: 992px) {
  .cart-product-head-container {
    display: block;
  }
}

@media (min-width: 1280px) {
  .empty-main-ttl {
    display: none;
  }

  .cart-prodict-type th,
  .cart-prodict-type td {
    padding: 3.5px 0;
    padding-right: 74px;
  }

  .cart-prodict-type th:last-child,
  .cart-prodict-type td:last-child {
    padding-right: 0;
  }
}

@media (min-width: 1280.88px) {
  .yt-cm-lt-col {
    padding-right: 10px;
  }

  .yt-cm-rt-col {
    padding-left: 10px;
  }
}

@media (min-width: 1900px) {
  .yt-cm-lt-col {
    padding-right: 20px;
  }

  .yt-cm-rt-col {
    padding-left: 20px;
  }

  .cart-product-title {
    line-height: 1.67;
    max-width: 100%;
  }

  .cart-produt-list-wrap {
    padding: 40px 40px 37px;
  }
}

/*
* Screen for under 1280 Resolution 
*/

@media (max-width: 1279.92px) {
  .cart-pg-list-prdt-info {
    width: 65%;
  }

  .empty-cartn-ttl {
    font-size: 24px;
    line-height: 1.17;
  }

  .empty-cart-text {
    font-size: 14px;
    line-height: 1.14;
  }

  .empty-cart-btn {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .yt-empty-cart-wrap.cart-pg-inner-wrap {
    margin-top: 10px !important;
  }

  .cart-page-main-title {
    margin-bottom: 10px !important;
    font-size: 24px;
    line-height: 1.17;
  }

  .cart-produt-list-wrap {
    padding: 15px;
    margin-bottom: 10px;
  }

  .yt-cart-price-lister {
    padding: 20px 15px;
  }

  .cart-product-title {
    font-size: 16px;
    line-height: 1.13;
  }

  .cart-prodict-type th {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
  }

  .cart-prodict-type tr td {
    font-size: 16px;
    line-height: 1.31;
  }

  .cart-product-price {
    font-size: 20px;
    line-height: 1.15;
    letter-spacing: 0.77px;
  }

  .cart-prodict-amount thead th:first-child {
    font-size: 18px;
    line-height: 1.11;
  }

  .cart-prodict-amount thead th:last-child {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.13;
  }

  .cart-product-amount-ttl {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
  }

  .cart-product-amount-price {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
  }

  .cart-prodict-amount tbody td,
  .cart-prodict-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td {
    padding: 0px 0;
  }

  .cart-divider {
    margin: 15px 0;
  }

  .cart-proceed-to-checkput {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .cart-coupon .form-control {
    /* padding: 12px 15px; */
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
  }

  .cart-coupon .error .cart-coupon-code-message {
    font-size: 12px;
    line-height: 1.58;
  }

  .cart-coupon-change-btn {
    font-size: 14px;
    line-height: 1.14;
  }

  .checkout-form-wrap .yt-chekout-radio .yt-currency-wrap.form-group {
    padding-left: 0 !important;
  }
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 1024px) {
  .cart-pg-list-prdt-info {
    width: 67%;
  }
}

@media (max-width: 767.92px) {
  .cart-pg-list-prdt-info {
    width: 73%;
  }

  .cart-pg-inner-wrap .yt-chekout-radio .form-check-input:checked+label:after {
    top: 4px !important;
  }

  .cart-pg-list-image .cart-product-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  .cart-list-other-act {
    min-width: auto;
  }

  .cart-pg-list-image .cart-product-image {
    width: 70px;
    height: 70px;
  }

  .cart-checkout-wrap .cart-proceed-to-checkput {
    margin-bottom: 15px;
  }

  .yt-cm-row {
    margin-left: -20px;
    margin-right: -20px;
  }

  .yt-cm-lt-col {
    padding-right: 11px;
    padding-left: 11px;
  }

  .yt-cm-rt-col {
    padding: 0;
  }

  .empty-cartn-ttl {
    font-size: 17px;
    line-height: 1.12;
  }

  .empty-cart-text {
    font-size: 15px;
    line-height: 1.2;
  }

  .empty-cart-btn {
    font-size: 12px;
    line-height: 1.17;
  }

  .cart-page-main-title {
    font-size: 18px;
    line-height: 1.11;
  }

  .cart-produt-list-wrap {
    padding: 15px;
    margin-bottom: 10px;
  }

  .cart-product-title {
    font-size: 14px;
    line-height: 1.36;
  }

  .cart-prodict-type th {
    /* font-size: 10px; */
    line-height: 1.1;
    letter-spacing: 0.38px;
  }

  .cart-prodict-type tr td {
    font-size: 14px;
    line-height: 1.5;
  }

  .cart-product-price {
    font-size: 15px;
    line-height: 1.2;
  }

  .cart-product-delete svg {
    font-size: 15px;
  }

  .cart-page-main-title {
    margin-bottom: 10px !important;
  }

  .yt-cart-price-lister {
    border-radius: 0;
    padding: 20px 18px;
    margin-bottom: 10px;
  }

  .cart-prodict-amount thead th:first-child {
    font-size: 16px;
    line-height: 1.19;
  }

  .cart-prodict-amount thead th:last-child {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.27;
  }

  .cart-product-amount-ttl {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.2;
  }

  .cart-product-amount-price {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
  }

  .cart-prodict-amount tbody td,
  .cart-prodict-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td {
    padding: 0px 0;
  }

  .cart-divider {
    margin: 3px 0 7px;
  }

  .cart-coupon .form-control {
    /* padding: 15px; */
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
  }

  .edit-profile-form.text-right .btn-secondary {
    color: #fff;
    background-color: #324688 !important;
    border-color: #324688 !important;
  }

  .cart-proceed-to-checkput {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: 0.4px;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }

  .checkout-form-wrap .cart-pg-inner-wrap {
    padding: 20px;
    margin-bottom: 10px;
  }

  .cart-checkout-tile {
    font-size: 15px;
    line-height: 1.2;
  }

  .cart-select-aar-btn {
    font-size: 12px;
    line-height: 1.17;
  }

  .checkout-form-label {
    font-size: 12px;
    line-height: 1.17;
  }

  .cart-pg-inner-wrap .checkout-checkbox .form-check-input {
    font-size: 10px;
    line-height: 1.1;
  }

  .checkout-form-wrap .yt-chekout-radio .form-check-label {
    font-size: 12px;
    line-height: 1.17;
  }

  .checkout-form-wrap .yt-chekout-radio .form-check-label .form-check-input {
    margin-top: 0px;
  }

  /* .checkout-form-wrap .yt-chekout-radio > div {
    width: 100%;
    margin-bottom: 10px;
  } */
  .cart-pg-inner-wrap .yt-checkout-form-rado-box {
    top: 6px;
  }
}

@media (max-width: 575.98px) {
  .empty-cart-text {
    max-width: 233px;
    margin-left: auto;
    margin-right: auto;
  }

  .empty-cart-btn {
    width: 100%;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-chekout-radio .checkout-form-label {
    font-size: 16px;
    line-height: 1.13;
  }

  .yt-inner-wrap .yt-items.item {
    padding-top: 30px;
  }

  .empty-cart-btn {
    font-size: 16px;
  }

  .cart-page-main-title {
    font-size: 26px;
  }

  .cart-proceed-to-checkput {
    font-size: 16px;
  }

  .cart-produt-list-wrap {
    padding: 30px;
    margin-bottom: 20px !important;
  }

  .yt-cart-price-lister {
    padding: 10px 40px 24px 40px;
  }

  .cart-divider {
    margin: 10px 0 !important;
  }

  form.yt-cart-disct-wrap {
    padding: 10px 0 !important;
  }

  .cart-prodict-amount tbody tr:last-child td {
    padding-bottom: 0px;
  }

  table.yt-sub-ttl-tbl-wrap.table tr:first-child td {
    padding-top: 0px;
  }

  table.yt-sub-ttl-tbl-wrap.table {
    margin: 0;
  }
}

.cartContainer {
  padding-top: 55px !important;
}

.myToggle {
  float: right;
}

.myToggle span {
  font-size: 17px;
  text-transform: uppercase;
  font-weight: normal;
}

.pr-20 {
  padding-right: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

/*Cart Start*/
.yt-cart-price-lister {
  padding: 10px 40px 24px 40px;
  margin-bottom: 24px;
}

.cart-prodict-amount th {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  text-align: left;
  color: var(--color-black);
}

.cart-prodict-amount thead th {
  padding-top: 0;
  padding-bottom: 5px;
  padding-left: 0;
  padding-right: 18px;
}

.cart-prodict-amount tbody td,
.cart-prodict-total-amount tbody td,
.ccart-prodict-sub-total-amount tbody td,
.cart-prodict-sub-total-amount tbody td {
  padding: 0px 0;
}

.cart-prodict-amount thead th:last-child {
  text-align: right;
}

.cart-product-amount-ttl {
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.color-dark {
  color: var(--color-black) !important;
}

.cart-product-amount-price {
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #000000;
  display: block;
}

.cart-divider {
  border-bottom: solid 1px #e2e2e2 !important;
  display: block;
  margin: 15px 0 20px;
}

.cart-coupon .form-group {
  position: relative;
}

.cart-coupon .form-control {
  border-radius: 6px;
  border: solid 1px #dbdbdb;
  height: auto;
  box-shadow: none;
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  padding: 21px;
}

.cart-coupon-btn {
  position: absolute !important;
  top: 50% !important;
  right: 12px !important;
  opacity: 0.99 !important;
  border-radius: 5px !important;
  background-color: var(--button-primary-hover) !important;
  color: var(--button-text-primary-hover) !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  transform: translateY(-50%) !important;
  line-height: 1.15 !important;
  padding: 8px 15px !important;
}

.cart-coupon-btn:hover {
  transition: 0.5s ease;
  background-color: var(--button-primary) !important;
  color: var(--button-text-primary) !important;
}

.cart-coupon .error .cart-coupon-code-message {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-orange);
}

.cart-coupon .success .cart-coupon-code-message {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-green);
}

.cart-coupon-change-btn {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-orange);
  text-decoration: underline;
}

.cart-coupon .cart-coupon-bottom-wrapper {
  position: absolute;
  bottom: -42px;
  left: 0;
  right: 0;
  opacity: 0;
}

.cart-coupon .error .cart-coupon-bottom-wrapper,
.cart-coupon .success .cart-coupon-bottom-wrapper {
  opacity: 1;
}

form.yt-cart-disct-wrap .yt-form-cpn-err.form-group,
form.yt-cart-disct-wrap .success.form-group {
  margin-bottom: 20px !important;
}

.cart-coupon .success .cart-coupon-bottom-wrapper .error-message,
.cart-coupon .error .cart-coupon-bottom-wrapper .success-message,
.login-on-cart-page .lp-bottom-links {
  display: none;
}

.availableCoupons {
  color: #3b5998;
  display: block;
  cursor: pointer;
}

.availableCoupons:hover {
  transition: 0.5s ease;
  text-decoration: underline;
}

.cart-product {
  display: flex;
  flex-direction: column;
}

.cart-customization {
  font-size: 15px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.cart-customization span {
  color: #f67054;
}

.credit-div {
  display: flex;
}

.credit-div span {
  width: 100%;
}

.cart-proceed {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 1.11 !important;
  text-align: center !important;
  background-color: #364f6b !important;
  border-color: #364f6b !important;
  color: #fff !important;
  cursor: pointer;
  /* margin-left: 15px;
  margin-top: 0;
  margin-bottom: 15px; */
  width: 100%;
}

.cart-proceed:hover {
  transition: 0.5s ease;
  border-color: #364f6b !important;
  background-color: #576c85 !important;
  color: #fff !important;
}

.address-btn-container .cart-proceed {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 1.11 !important;
  text-align: center !important;
  background-color: #364f6b !important;
  border-color: #364f6b !important;
  color: #fff !important;
  cursor: pointer;
  /* margin-left: 15px;
  margin-top: 0;
  margin-bottom: 15px; */
  width: 100%;
}

.address-btn-container .cart-proceed:hover {
  transition: 0.5s ease;
  border-color: #364f6b !important;
  background-color: #576c85 !important;
  color: #fff !important;
}

.my-span {
  color: #7c8188;
  font-size: 15px;
}

.my-span label {
  color: #ffa500;
}

.cart-coupon-change-btn {
  color: #3b5998 !important;
  cursor: pointer !important;
}

.cart-coupon-change-btn:hover {
  text-decoration: underline !important;
}

/*Cart End*/

.modalTitleInputLable {
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2 !important;
  letter-spacing: normal;
  text-align: left;
  color: #8b8f95;
}

.non-empty-Address-box {
  width: 1160px;
  height: 145px;
  margin: 16px 0;
  border-radius: 10px;
  background-color: #ffffff;
}

.address-marker {
  width: 40px;
  height: 30px;
  object-fit: contain;
}

.cart-address-new {
  /* font-family: GTWalsheimPro; */
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  word-break: break-all;
  font-weight: 600;
}

.cart-sub-address {
  color: #7c8188;
  font-family: GTWalsheimPro;
  font-size: 14px;
}

.cart-sub-address p {
  color: var(--button-primary-hover) !important;
  font-size: 13px;
}

.Home {
  margin: 9px 814px 7px 32px;
  font-family: GTWalsheimPro;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1c213a;
}

.Mask {
  width: 65px;
  height: 65px;
  margin: 0 32px 4px 0;
  padding: 20px 22.3px 20.3px 22px;
  object-fit: contain;
  background-color: #f6f8fa;
}

.selectedAddress {
  width: 29px;
  height: 29px;
  margin: 4px 0 6px 100px;
  object-fit: contain;
}

.saveUserAddress {
  width: 155px;
  height: 50px;
  margin: 25px 40px 20px 40px;
  opacity: 0.99;
  border-radius: 6px;
  background-color: #3f7a4f;
}

/* Create a custom checkbox */
.mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: lightgray;
  border: 1px solid #000000;
}

.container:hover input~.mark {
  background-color: white;
}

.container input:checked~.mark {
  background-color: blue;
}

/* Create the mark/indicator (hidden when not checked) */
.mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the mark when checked */
.container input:checked~.mark:after {
  display: block;
}

.header-modal {
  text-align: center;
  padding-left: 120px;
}

.custom-modal-style {
  width: 897px !important;
  height: 802px !important;
}

.address-container {
  height: 24px;
}

.address-block {
  margin: 8px 0px 0px 0px;
}

.main {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 20px;
}

/* Hide the default checkbox */
.order-checkbox input[type="checkbox"] {
  visibility: hidden;
}

.mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: rgb(250, 250, 250);
  border-radius: 5px;
}

.main input:checked~.mark {
  background-color: green;
}

.geekmark:after {
  content: "";
  position: absolute;
  display: none;
}

.main input:checked~.mark:after {
  display: block;
}

.main .mark:after {
  left: 8px;
  bottom: 6px;
  width: 9px;
  height: 17px;
  border: solid white;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.order-radioBtn {
  display: inline-flex;
}

.order-radioBtn:not(:last-child) {
  /* margin-right: 40px; */
}

.order-radioBtn label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.order-radioBtn input[type="radio"] {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  outline: none;
  border: 1px solid gray;
  margin: 0px;
}

input[type="radio"]:before {
  content: "";
  width: 12px;
  height: 12px;
  margin: 21% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: var(--button-primary-hover) !important;
}

input[type="radio"]:checked {
  background: radial-gradient(teal 0%, teal 40%, transparent 50%, transparent);
  border-color: var(--button-primary-hover) !important;
}

/* .role {
        margin-right: 80px;
        margin-left: 20px;
        font-weight: normal;
    }*/

.checkbox label {
  margin-bottom: 20px !important;
}

.focus-border-none {
  outline: none;
  border-width: 0px;
  border: none;
}

.my-cart-applied-code {
  display: flex;
  justify-content: space-between;
}

.applied-coupon-code {
  color: #6cb83a;
  display: block;
}

.my-cart-engagements {
  display: flex;
  justify-content: space-between;
}

.cart-coupon .error .cart-coupon-code-message {
  font-size: 13px;
  line-height: 1.01 !important;
  text-align: left;
  color: var(--color-orange);
}

.cart-coupon .success .cart-coupon-code-message {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-green);
}

.cart-coupon .error .cart-coupon-code-message {
  /*  Column Three  Cart */
  font-size: 12px;
  line-height: 1.58;
}

.reward-balance-span {
  font-size: 14px;
  line-height: 1.43;
  color: var(--color-gray);
}

.reward-balance-span label {
  color: var(--color-gold-yellow);
}

.main-container {
  padding: 0px;
}

.bk-btn {
  height: 60px;
  width: 60px;
  background-color: red;
  border-radius: 50%;
}

.bk-btn-triangle {
  position: relative;
  top: 20px;
  left: 10px;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-right: 13px solid white;
}

.bk-btn-bar {
  position: relative;
  background-color: white;
  height: 8px;
  width: 25px;
  top: 4px;
  left: 23px;
}

.cart-prodict-amount .yt-login-bottom-info {
  padding-top: 0;
  margin-top: 0 !important;
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-cart-price-lister {
    /*  Column Three  Cart */
    padding: 10px 40px 24px 40px;
  }

  .cart-prodict-amount tbody tr:last-child td {
    /*  Column Three  Cart */
    padding-bottom: 0px;
  }

  table.yt-sub-ttl-tbl-wrap.table tr:first-child td {
    /*  Column Three  Cart */
    padding-top: 0px;
  }

  table.yt-sub-ttl-tbl-wrap.table {
    /*  Column Three  Cart */
    margin: 0;
  }

  .cart-divider {
    /*  Column Three  Cart */
    margin: 10px 0 !important;
  }

  form.yt-cart-disct-wrap {
    /*  Column Three  Cart */
    padding: 10px 0 !important;
  }
}

@media (max-width: 1279.92px) {
  .yt-cart-price-lister {
    /*  Column Three  Cart */
    padding: 20px 15px;
  }

  .order-radioBtn:not(:last-child) {
    /* margin-right: 20px; */
  }

  .checkout-form-label {
    font-size: 14px;
  }

  .cart-prodict-amount thead th:first-child {
    /*  Column Three  Cart */
    font-size: 18px;
    line-height: 1.11;
  }

  .cart-prodict-amount thead th:last-child {
    /*  Column Three  Cart */
    font-size: 16px;
    font-weight: normal;
    line-height: 1.13;
  }

  .cart-prodict-amount tbody td,
  .cart-prodict-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td {
    /*  Column Three  Cart */
    padding: 0px 0;
  }

  .cart-prodict-amount thead th:first-child {
    /*  Column Three  Cart */
    font-size: 16px;
    line-height: 1.19;
  }

  .cart-prodict-amount thead th:last-child {
    /*  Column Three  Cart */
    font-size: 15px;
    font-weight: normal;
    line-height: 1.27;
  }

  .cart-product-amount-ttl {
    /*  Column Three  Cart */
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
  }

  .cart-product-amount-price {
    /*  Column Three  Cart */
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
  }

  .cart-divider {
    /*  Column Three  Cart */
    margin: 15px 0 !important;
  }

  .cart-coupon .form-control {
    /*  Column Three  Cart */
    padding: 12px 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
  }

  .cart-coupon .error .cart-coupon-code-message {
    /*  Column Three  Cart */
    font-size: 12px;
    line-height: 1.58;
  }

  .cart-coupon-change-btn {
    /*  Column Three  Cart */
    font-size: 14px;
    line-height: 1.14;
  }
}

@media (min-width: 768px) {

  .cart-prodict-amount tbody td,
  .cart-prodict-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td {
    /*  Column Three  Cart */
    padding: 0px 0;
  }
}

@media (max-width: 768px) {
  .yt-cart-price-lister {
    /*  Column Three  Cart */
    border-radius: 0;
    padding: 20px 18px;
    margin-bottom: 10px;
    margin-top: 0;
  }

  .cart-product-amount-ttl {
    /*  Column Three  Cart */
    font-size: 15px;
    font-weight: normal;
    line-height: 1.2;
  }

  .cart-divider {
    /*  Column Three  Cart */
    margin: 3px 0 7px !important;
  }

  .cart-coupon .form-control {
    /*  Column Three  Cart */
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
  }

  .cart-product-amount-price {
    /*  Column Three  Cart */
    font-size: 14px;
    line-height: 1.2;
    font-weight: normal;
  }

  .address-btn-container .cart-proceed {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 1.11 !important;
    text-align: center !important;
    background-color: #364f6b !important;
    border-color: #364f6b !important;
    color: #fff !important;
    cursor: pointer;
    /* margin-left: 15px;
    margin-top: 0;
    margin-bottom: 15px; */
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  
  .address-btn-container .cart-proceed:hover {
    transition: 0.5s ease;
    border-color: #364f6b !important;
    background-color: #576c85 !important;
    color: #fff !important;
  }
}

.radio-btn {
  margin: 0.5rem;
}

.radio-btn .radio-class {
  position: absolute;
  opacity: 0;
}

.radio-btn .radio-class+.radio-label:before {
  content: '';
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}

.radio-btn .radio-class:checked+.radio-label:before {
  background-color: var(--button-primary-hover) !important;
  box-shadow: inset 0 0 0 4px #f4f4f4;
}

.radio-btn .radio-class:focus+.radio-label:before {
  outline: none;
  border-color: var(--button-primary-hover) !important;
}

.radio-btn .radio-class:disabled+.radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}

.radio-btn .radio-class+.radio-label:empty:before {
  margin-right: 0;
}