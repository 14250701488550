.pp-od-no-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.pp-od-no-text.mb-0 {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.profile-pg-inner-no-order {
  margin-top: 35px;
}

@media (max-width: 1279.92px) {
  .pp-sa-order-wrap {
    /*Column Three Empty Cart*/
    margin-bottom: 30px !important;
  }
  .pp-od-no-text {
    /*Column Three Empty Cart*/
    font-size: 14px;
    line-height: 1.14;
  }

}

@media (max-width: 767.92px) {
  .profile-pg-inner-wrap.profile-pg-inner-no-order {
    /*Column Three Empty Cart*/
    padding: 20px 9px;
  }
  .pp-od-no-text {
    /*Column Three Empty Cart*/
    font-size: 15px;
    line-height: 1.2;
    max-width: 233px;
    margin: 0 auto;
  }

}
