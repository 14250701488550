/* Location Modal */
.main-location-title {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.dashboard-carousel-section {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 64px !important;
  padding-right: 64px !important;
}
.banner-section {
  padding-top: 40px;
}
.pp-products-display {
  display: flex;
  justify-content: space-between;
}
.w-730 {
  width: 730px;
}
.w-542 {
  width: 542px;
}
.location-subTitle {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-gray);
}
.location-allow-btn {
  background-color: var(--button-primary) !important;
  color: var(--button-text-primary) !important;
  opacity: 0.99;
  border-radius: 6px;
}
.location-allow-btn:hover {
  background-color: var(--button-primary-hover) !important;
  color: var(--button-text-primary-hover) !important;
}

/*Common Start*/

.left-arrow {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 45px;
  height: 45px;
  cursor: pointer;
  left: 15px;
  font-size: 20px;
  fill: black;
}
.right-arrow {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 45px;
  height: 45px;
  cursor: pointer;
  right: 15px;
  font-size: 20px;
  fill: black;
}
.hoverMe {
  cursor: pointer;
}
.pp-filter-products {
  z-index: 0;
  /* background-color: #f2f6f8; */
}
.banner-info {
  margin-left: 100px;
  line-height: 2;
  color: rgb(54, 79, 107);
}
.banner-info span {
  font-size: 25px;
  font-weight: 300;
}
.prod-btn button {
  padding: 8px 25px;
  background-color: transparent;
  border: none;
  font-size: 22px !important;
}
@media (max-width: 1280px) {
  .w-730 {
    width: 665px;
  }
  .w-542 {
    width: 462px;
  }
}
@media (max-width: 1024px) {
  .w-730 {
    width: 562px;
  }
  .w-542 {
    width: 374px;
  }
}

@media (max-width: 767px) {
  .dashboard-carousel-section {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .pp-products-display {
    display: block;
  }
  .w-730 {
    width: 680px;
  }
  .w-542 {
    width: 680px;
  }
}

@media (min-width: 767.98px) {
  .pp-filter-products {
    position: relative;
  }
}
/* @media (max-width: 767.88px) {
  .pp-filter-products {
    z-index: 0;
    background-color: #f2f6f8;
  }
} */
@media (max-width: 500px) {
  .w-730 {
    width: 345px;
  }
  .w-542 {
    width: 345px;
  }
}

/*Common End*/
